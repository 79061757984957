import { FC } from 'react';
import { useSelector } from 'react-redux';
import { loginStatusSelector } from '@doda/common/src/redux/features/memberInfo';
import { useAnalyticsSetup } from '@doda/common/src/hooks/useAnalytics';
import { PushNotification } from '@doda/common/src/components/Head/PushNotification';
import { Tag } from '@doda/common/src/components/Head/metaTag';
import { useScrollToHash } from '@doda/common/src/hooks/useScrollToHash';
import {
  OGP_IMAGE,
  OGP_SITE,
  OGP_TYPE,
  OGP_URL_TOP,
} from '@doda/common/src/constants/properties/arca';
import { useCmsMemberInfo } from '@doda/common/src/hooks/useCmsMemberInfo';
import {
  UrgentMessage,
  type UrgentMessageLink,
} from '../../components/UrgentMessage';
import { BrandPanel, KeyVisual } from '../../components/BrandPanel';
import { SignupLoginArea } from '../../components/SignupLoginArea';
import styles from './index.module.scss';
import {
  RightSideNavigationTopBanner,
  SideNavTopLinkBanner,
} from '../../components/SideNavTopLinkBanner';
import { useChatParts } from '../../hooks/useChatParts';
import {
  PopularJobsFeature,
  type PopularJobsFeatureProps,
} from '../../components/PopularJobsFeature';
import { EventSeminar, EventSeminarProps } from '../../components/EventSeminar';
import { SideNavMiddleLinkBanner } from '../../components/SideNavMiddleLinkBanner';
import { SideNavMiddleLinkImageBanner } from '../../components/SideNavMiddleLinkBanner/types';
import {
  NewArrivalsFocusedContents,
  NewArrivalsFocusedContentsProps,
} from '../../components/NewArrivalsFocusedContents';
import {
  NoticesFromDoda,
  NoticesFromDodaListProps,
} from '../../components/NoticesFromDoda';
import {
  BusinessClientInfo,
  BusinessClientInfoListProps,
} from '../../components/BusinessClientInfo';
import { SideNavBottomLinkBanner } from '../../components/SideNavBottomLinkBanner';
import { SideNavBottomLinkImageBanner } from '../../components/SideNavBottomLinkBanner/types';
import { PR, PRProps } from '../../components/PR';
import {
  SearchMenuLogOut,
  SearchMenuLogOutProps,
} from '../../components/SearchMenuLogOut';
import { JobSearchByWorkStyle } from '../../components/JobSearchByWorkStyle';
import { JobSuggestionService } from '../../components/JobSuggestionService';
import { KnowAndAskContents } from '../../components/KnowAndAskContents';
import { SiteDescription } from '../../components/SiteDescription';
import {
  RecommendForYou,
  RecommendForYouProps,
} from '../../components/RecommendForYou';
import {
  DiagnosticDocumentTool,
  DiagnosticDocumentToolProps,
} from '../../components/DiagnosticDocumentTool';
import {
  PopularServices,
  PopularServicesProps,
} from '../../components/PopularServices';
import {
  RelatedWebsites,
  RelatedWebsitesProps,
} from '../../components/RelatedWebsites';
import { SpecialistSiteSection } from '../../components/SpecialistSiteSection';
import ToDoListArea from '../../components/ToDoListArea/containers';
import { SuggestedJobsProps } from '../../components/SuggestedJobs';
import SuggestedJobs from '../../components/SuggestedJobs/containers';
import {
  SearchMenuLogIn,
  SearchMenuLogInProps,
} from '../../components/SearchMenuLogIn';
import { JobTypesSection } from '../../components/JobTypesSection';
import { getJobTypeList } from '../../components/JobTypesSection/logics/getJobTypeList';
import { PasswordChangeNotificationHeader } from '../../components/PasswordChangeNotificationHeader';
import { RecommendPanelLogOut } from '../../components/RecommendPanelLogOut';
import { RecommendPanelTop as LogoutRecommends } from '../../components/RecommendPanelLogOut/types';
import { RecommendPanelLogIn } from '../../components/RecommendPanelLogIn';
import {
  RecommendPanelTop as LoginRecommends,
  TopB4rTsuukaRecommendType,
} from '../../components/RecommendPanelLogIn/types';
import { PasswordChangeNotificationModal } from '../../components/PasswordChangeNotificationModal';
import { QuickSearchLogIn } from '../../components/QuickSearchLogIn';
import {
  ApplicationUplift,
  ApplicationUpliftProps,
} from '../../components/ApplicationUplift';
import { QuickSearchLogOut } from '../../components/QuickSearchLogOut';
import { WorkLocationSection } from '../../components/WorkLocationSection';
import { PremiumOffer, PremiumOfferProps } from '../../components/PremiumOffer';
import { PageImpression } from '../../components/PageImpression';
import { useMeasureChatPopupClick } from '../../hooks/useMeasureChatPopupClick';
import { useMeasureGoogleAnalyticsId } from '../../hooks/useMeasureGoogleAnalyticsId';
import { webPageJsonData, webSiteJsonData } from '../../constants/meta';

import { useMeasureRecommend } from '../../hooks/useMeasureRecommend';

/**
 * トップページまとめコンポネントのProps
 */
export interface TopScreenProps {
  announcementList: UrgentMessageLink[];
  keyVisual: KeyVisual;
  totalCountWithPrivateJobs: number;
  rightSideNavigationTopBanners: RightSideNavigationTopBanner[];
  popularJobsFeature: PopularJobsFeatureProps;
  eventSeminars: EventSeminarProps;
  rightSideNavigationCenterBanners: SideNavMiddleLinkImageBanner[];
  newArrivalsFocusedContents: NewArrivalsFocusedContentsProps;
  noticesFromDoda: NoticesFromDodaListProps;
  businessClientInformation: BusinessClientInfoListProps;
  rightSideNavigationBottomBanners: SideNavBottomLinkImageBanner[];
  pr: PRProps;
  searchMenuLogOut: SearchMenuLogOutProps;
  recommendationForYou: RecommendForYouProps;
  diagnosticDocumentTool: DiagnosticDocumentToolProps;
  popularServices: PopularServicesProps;
  relatedWebsites: RelatedWebsitesProps;
  suggestedJobs: SuggestedJobsProps;
  searchMenuLogIn: SearchMenuLogInProps;
  jobTypeList: ReturnType<typeof getJobTypeList>;
  isShowPasswordNotification: boolean;
  logoutRecommends: LogoutRecommends[];
  loginRecommends: LoginRecommends[];
  isPopUpPasswordNotification: boolean;
  publicJobCount: number;
  publicationDate: string;
  applicationUplift: ApplicationUpliftProps;
  premiumOffer: PremiumOfferProps;
  googleAnalyticsId: string;
  // DODASITE-788_B4R×通過スコアモデル_ABテスト
  topB4rTsuukaRecommendType: TopB4rTsuukaRecommendType;
}

export const TopScreen: FC<TopScreenProps> = ({
  announcementList,
  keyVisual,
  totalCountWithPrivateJobs,
  rightSideNavigationTopBanners,
  popularJobsFeature,
  eventSeminars,
  rightSideNavigationCenterBanners,
  newArrivalsFocusedContents,
  noticesFromDoda,
  businessClientInformation,
  rightSideNavigationBottomBanners,
  pr,
  searchMenuLogOut,
  recommendationForYou,
  diagnosticDocumentTool,
  popularServices,
  relatedWebsites,
  suggestedJobs,
  searchMenuLogIn,
  jobTypeList,
  isShowPasswordNotification,
  logoutRecommends,
  loginRecommends,
  isPopUpPasswordNotification,
  publicJobCount,
  publicationDate,
  applicationUplift,
  premiumOffer,
  googleAnalyticsId,
  topB4rTsuukaRecommendType,
}) => {
  const isLoggedIn = useSelector(loginStatusSelector);
  useCmsMemberInfo('pcHeader');
  useChatParts();
  useAnalyticsSetup();
  useMeasureChatPopupClick();
  useMeasureGoogleAnalyticsId(isLoggedIn, googleAnalyticsId);
  const {
    setObserverRef,
    handleClickThumbnail,
    handleClickCardDetail,
    handleClickFavorite,
    getQueryParamOnClick,
  } = useMeasureRecommend(isLoggedIn);
  useScrollToHash(0);
  return (
    <>
      <PageImpression isLoggedIn={isLoggedIn} />
      <Tag
        title="転職ならdoda(デューダ) 求人､転職情報満載の転職サイト"
        description="dodaは業界最大級の支援実績で、転職相談、求人紹介・検索・応募、企業からのスカウト、面接対策のお手伝いまで転職を全面的にサポートする完全無料の転職サイト。大手・優良企業、完全週休二日、未経験者歓迎など求人多数。"
        keywords="転職,転職情報,転職サイト,求人,求人情報,非公開求人,doda,デューダ,ドーダ,ドゥーダ,duda,でゅーだ"
        type={OGP_TYPE}
        url={OGP_URL_TOP}
        image={OGP_IMAGE}
        siteName={OGP_SITE}
        canonical="/"
        webSiteStructuredData={JSON.stringify(webSiteJsonData)}
        webPageStructuredData={JSON.stringify(webPageJsonData)}
      />
      <PushNotification />
      <UrgentMessage urgentMessageLinkList={announcementList} />
      {isLoggedIn ? (
        <>
          {isPopUpPasswordNotification && <PasswordChangeNotificationModal />}
          <PasswordChangeNotificationHeader
            isShowPasswordNotification={isShowPasswordNotification}
          />
          <ToDoListArea />
          <SuggestedJobs
            careerAdviserJob={suggestedJobs.careerAdviserJob}
            recruitmentProjectJob={suggestedJobs.recruitmentProjectJob}
            companyRecruiterJob={suggestedJobs.companyRecruiterJob}
            partnerAgentJob={suggestedJobs.partnerAgentJob}
          />
          <QuickSearchLogIn
            totalCount={publicJobCount}
            publicationDate={publicationDate}
          />
          <SearchMenuLogIn
            hasNewArrivalMatchJob={searchMenuLogIn.hasNewArrivalMatchJob}
            searchConditionParam={searchMenuLogIn.searchConditionParam}
            isDesiredCond={searchMenuLogIn.isDesiredCond}
            desiredCondition={searchMenuLogIn.desiredCondition}
          />
        </>
      ) : (
        <>
          <BrandPanel keyVisual={keyVisual} />
          <div className={styles.top__signupLoginArea}>
            <div className={styles.top__signupLogin}>
              <SignupLoginArea
                totalCountWithPrivateJobs={totalCountWithPrivateJobs}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.top__main}>
        <div className={styles.top__mainLeft}>
          {isLoggedIn ? (
            <>
              {loginRecommends.map((recommendJobList) => (
                <div
                  key={recommendJobList.recommendType}
                  ref={(element) =>
                    setObserverRef(
                      element,
                      recommendJobList.recommendType,
                      recommendJobList.results
                    )
                  }
                  className={styles.top_recommendWrapper}
                >
                  <RecommendPanelLogIn
                    key={recommendJobList.recommendType}
                    recommendJobList={recommendJobList}
                    handleClickThumbnail={handleClickThumbnail}
                    handleClickCardDetail={handleClickCardDetail}
                    handleClickFavorite={handleClickFavorite}
                    queryParamOnClick={getQueryParamOnClick(
                      recommendJobList.recommendType
                    )}
                    topB4rTsuukaRecommendType={topB4rTsuukaRecommendType}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              <QuickSearchLogOut
                totalCount={publicJobCount}
                publicationDate={publicationDate}
              />
              <SearchMenuLogOut
                isHistoryId={searchMenuLogOut.isHistoryId}
                isDesiredCond={searchMenuLogOut.isDesiredCond}
                isDesiredCondCookie={searchMenuLogOut.isDesiredCondCookie}
                hasNewArrivalMatchJob={searchMenuLogOut.hasNewArrivalMatchJob}
                matchJobCount={searchMenuLogOut.matchJobCount}
                hasNewArrivalSearchCondition={
                  searchMenuLogOut.hasNewArrivalSearchCondition
                }
                previousSearchConditionCount={
                  searchMenuLogOut.previousSearchConditionCount
                }
                desiredCondition={searchMenuLogOut.desiredCondition}
                searchConditionParam={searchMenuLogOut.searchConditionParam}
              />
              {logoutRecommends.map((recommendJobList) => (
                <div
                  key={recommendJobList.recommendType}
                  ref={(element) =>
                    setObserverRef(
                      element,
                      recommendJobList.recommendType,
                      recommendJobList.results
                    )
                  }
                >
                  <RecommendPanelLogOut
                    recommendJobList={recommendJobList}
                    handleClickThumbnail={handleClickThumbnail}
                    handleClickCardDetail={handleClickCardDetail}
                    handleClickFavorite={handleClickFavorite}
                    queryParamOnClick={getQueryParamOnClick(
                      recommendJobList.recommendType
                    )}
                  />
                </div>
              ))}
            </>
          )}
          <JobTypesSection
            listDataLeft={jobTypeList.left}
            listDataRight={jobTypeList.right}
          />
          <WorkLocationSection isLoggedIn={isLoggedIn} />
          <JobSearchByWorkStyle isLoggedIn={isLoggedIn} />
          <JobSuggestionService isLoggedIn={isLoggedIn} />
          <KnowAndAskContents isLoggedIn={isLoggedIn} />
          <RecommendForYou
            heading={recommendationForYou.heading}
            recommendForYouLinks={recommendationForYou.recommendForYouLinks}
          />
          <DiagnosticDocumentTool
            title={diagnosticDocumentTool.title}
            banners={diagnosticDocumentTool.banners}
          />
          <PopularServices
            title={popularServices.title}
            banners={popularServices.banners}
          />
          <RelatedWebsites banners={relatedWebsites.banners} />
          <SpecialistSiteSection isLoggedIn={isLoggedIn} />
          <SiteDescription />
        </div>
        <div className={styles.top__aside}>
          <SideNavTopLinkBanner
            sideNavTopLinkImageBannerList={rightSideNavigationTopBanners}
          />
          <PopularJobsFeature
            jobFeatures={popularJobsFeature.jobFeatures}
            heading={popularJobsFeature.heading}
            listHref={popularJobsFeature.listHref}
            listText={popularJobsFeature.listText}
          />
          <EventSeminar
            eventSeminars={eventSeminars.eventSeminars}
            heading={eventSeminars.heading}
            listText={eventSeminars.listText}
            listHref={eventSeminars.listHref}
          />
          <NewArrivalsFocusedContents
            heading={newArrivalsFocusedContents.heading}
            contents={newArrivalsFocusedContents.contents}
            banners={newArrivalsFocusedContents.banners}
            listHref={newArrivalsFocusedContents.listHref}
            listText={newArrivalsFocusedContents.listText}
            listDestinationUrl={newArrivalsFocusedContents.listDestinationUrl}
          />
          <SideNavMiddleLinkBanner
            sideNavMiddleLinkImageBannerList={rightSideNavigationCenterBanners}
          />
          <NoticesFromDoda
            heading={noticesFromDoda.heading}
            noticesFromDodaLink={noticesFromDoda.noticesFromDodaLink}
          />
          <BusinessClientInfo
            heading={businessClientInformation.heading}
            businessClientInfoLink={
              businessClientInformation.businessClientInfoLink
            }
          />
          <SideNavBottomLinkBanner
            rightSideNavigationBottomBanners={rightSideNavigationBottomBanners}
          />
          <PR heading={pr.heading} prLinkList={pr.prLinkList} />
          <ApplicationUplift
            isShowModal={applicationUplift.isShowModal}
            detailUrl={applicationUplift.detailUrl}
            isInterview={applicationUplift.isInterview}
            isBalloon={applicationUplift.isBalloon}
            listUrl={applicationUplift.listUrl}
            jobDetail={applicationUplift.jobDetail}
          />
        </div>
      </div>
      {premiumOffer.isShowModal && (
        <div className={styles.top_premiumOfferWrapper}>
          <PremiumOffer
            isShowModal={premiumOffer.isShowModal}
            scoutReason={premiumOffer.scoutReason}
            companyName={premiumOffer.companyName}
            recruiterImage={premiumOffer.recruiterImage}
            contentType={premiumOffer.contentType}
            messageTitle={premiumOffer.messageTitle}
            occupationName={premiumOffer.occupationName}
            message={premiumOffer.message}
            caseId={premiumOffer.caseId}
            scoutListId={premiumOffer.scoutListId}
            statusCheck={premiumOffer.statusCheck}
            recipientInformationId={premiumOffer.recipientInformationId}
            messageId={premiumOffer.messageId}
            displayControlId={premiumOffer.displayControlId}
          />
        </div>
      )}
    </>
  );
};
